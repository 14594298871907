import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import HomeView from '../views/HomeView.vue'

import DialPuzzle from "@/views/DialPuzzle.vue";
import MazePuzzle from "@/views/MazePuzzle.vue";
import ModulatorPuzzle from "@/views/ModulatorPuzzle.vue";
import PipesPuzzle from "@/views/PipesPuzzle.vue";
import RadioPuzzle from "@/views/RadioPuzzle.vue";
import ShufflePuzzle from "@/views/ShufflePuzzle.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dial',
    name: 'dial',
    component: DialPuzzle
  },
  {
    path: '/maze',
    name: 'maze',
    component: MazePuzzle
  },
  {
    path: '/modulator',
    name: 'modulator',
    component: ModulatorPuzzle
  },
  {
    path: '/pipes',
    name: 'pipes',
    component: PipesPuzzle
  },
  {
    path: '/radio',
    name: 'radio',
    component: RadioPuzzle
  },
  {
    path: '/shuffle',
    name: 'shuffle',
    component: ShufflePuzzle
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
