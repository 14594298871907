
import { Component, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {

  images: any[] = [
    {index: 9, img: require('@/assets/shuffle/10.png'), empty: false},
    {index: 1, img: require('@/assets/shuffle/02.png'), empty: false},
    {index: 3, img: require('@/assets/shuffle/04.png'), empty: false},
    {index: 5, img: require('@/assets/shuffle/06.png'), empty: false},
    {index: 6, img: require('@/assets/shuffle/07.png'), empty: false},
    {index: 4, img: require('@/assets/shuffle/05.png'), empty: false},
    {index: 14, img: require('@/assets/shuffle/15.png'), empty: false},
    {index: 2, img: require('@/assets/shuffle/03.png'), empty: false},
    {index: 13, img: require('@/assets/shuffle/14.png'), empty: false},
    {index: 11, img: require('@/assets/shuffle/12.png'), empty: false},
    {index: 12, img: require('@/assets/shuffle/13.png'), empty: false},
    {index: 7, img: require('@/assets/shuffle/08.png'), empty: false},
    {index: 0, img: require('@/assets/shuffle/01.png'), empty: false},
    {index: 8, img: require('@/assets/shuffle/09.png'), empty: false},
    {index: 10, img: require('@/assets/shuffle/11.png'), empty: false},
    {index: 15, img: require('@/assets/shuffle/16.png'), empty: true},
  ]

  hintImage = require('@/assets/shuffle/hint.png');

  isCompleted: boolean = false;

  move(image: any){
    if(this.isCompleted){
      return;
    }
    if(image.empty){
      return;
    }

    let imageIndex = this.images.indexOf(image);
    let empty = this.images.find(x => x.empty == true);
    let emptyIndex = this.images.indexOf(empty);

    let canMove = this.isNeighbourEmpty(imageIndex, emptyIndex);

    if(!canMove){
      return;
    }

    let temp = image;
    this.images[imageIndex] = empty;
    this.images[emptyIndex] = temp;

    this.isCompleted = this.validate();

    this.$forceUpdate();
    
    let order = this.images.map(x => {return x.index});
    
  }

  validate(): boolean{
    for(var i = 0; i < this.images.length; i++){
      if(this.images[i].index != i){
        return false;
      }
    }
    return true;
  }

  isNeighbourEmpty(imageIndex: number, emptyIndex: number): boolean{
    console.log("🔵 -> file: ShufflePuzzle.vue:107 -> extends -> isNeighbourEmpty -> imageIndex:", imageIndex);
    console.log("🔵 -> file: ShufflePuzzle.vue:107 -> extends -> isNeighbourEmpty -> emptyIndex:", emptyIndex);
    let size = 4;
    if(Math.abs(imageIndex - emptyIndex) == size){
      return true;
    }

    if(imageIndex + 1 == emptyIndex && imageIndex % size != size - 1){
      return true;
    }
    if(imageIndex - 1 == emptyIndex && imageIndex % size != 0){
      return true;
    }
    return false;
  }

  isHintDisplayed: boolean = false;
  showHint(){
    this.isHintDisplayed = true;
  }
}
