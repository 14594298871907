
import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "DialPuzzle",
})
export default class DialPuzzle extends Vue {
	password: string = "haurun";
	get passwordSequence(): any[] {
		let sequence: any[] = [];

		for (var letter of this.password.split("")) {
			let button = this.buttons.find(
				(btn) => btn.letters.indexOf(letter) >= 0
			);
			let index = button.letters.indexOf(letter);

			for (var i = 0; i <= index; i++) {
				sequence.push(button);
			}
		}

		return sequence;
	}

	get decodedInput(): string {
		let array: any[] = [];

		for (var i = 0; i < this.inputSequence.length; i++) {
			let button = this.inputSequence[i];
			if (array.length == 0) {
				array.push({ button: button, index: 0 });
				continue;
			}
			if (array[array.length - 1].button == button) {
				array[array.length - 1].index++;
			} else {
				array.push({ button: button, index: 0 });
			}
		}

		var result = "";
		for (var i = 0; i < array.length; i++) {
			result += array[i].button.letters[array[i].index];
		}
		return result.toUpperCase();
	}

	currentRoundSequence: any[] = [];

	mounted() {
		console.log(
			"password sequence",
			this.passwordSequence.map((x) => {
				return x.label;
			}),
			`length: ${this.passwordSequence.length}`
		);
		setTimeout(() => {
			this.showCurrentRoundSequence();
		}, 1000);
	}

	buttons: any[] = [
		{ label: "1", icon: "mdi-numeric-1-circle", letters: [], color: "grey" },
		{
			label: "2",
			icon: "mdi-numeric-2-circle",
			letters: ["a", "b", "c"],
			color: "red",
		},
		{
			label: "3",
			icon: "mdi-numeric-3-circle",
			letters: ["d", "e", "f"],
			color: "green",
		},
		{
			label: "4",
			icon: "mdi-numeric-4-circle",
			letters: ["g", "h", "i"],
			color: "blue",
		},
		{
			label: "5",
			icon: "mdi-numeric-5-circle",
			letters: ["j", "k", "l"],
			color: "teal",
		},
		{
			label: "6",
			icon: "mdi-numeric-6-circle",
			letters: ["m", "n", "o"],
			color: "pink",
		},
		{
			label: "7",
			icon: "mdi-numeric-7-circle",
			letters: ["p", "q", "r", "s"],
			color: "brown",
		},
		{
			label: "8",
			icon: "mdi-numeric-8-circle",
			letters: ["t", "u", "v"],
			color: "indigo",
		},
		{
			label: "9",
			icon: "mdi-numeric-9-circle",
			letters: ["w", "x", "y", "z"],
			color: "orange",
		},
		{ label: "*", icon: "mdi-asterisk", letters: [], color: "blue-grey" },
		{ label: "0", icon: "mdi-numeric-0-circle", letters: [], color: "lime" },
		{ label: "#", icon: "mdi-pound", letters: [], color: "cyan" },
	];

	lastButtonPressed: any = null;
	inputSequence: any[] = [];
	round: number = 1;
	color: string = "black";
	currentIndex = 0;
	inputDispabled: boolean = true;

	showCurrentRoundSequence() {
		this.inputDispabled = true;

		this.currentRoundSequence = this.passwordSequence.slice(0, this.round);

		setTimeout(() => {
			this.currentRoundSequence = [];
			this.inputDispabled = false;
		}, 2000);
	}

	buttonPressed(button: any) {
		this.inputDispabled = true;
		this.inputSequence.push(button);

		if (!this.validateSequence()) {
			this.reset();
			return;
		}

		if (this.inputSequence.length == this.round) {
			this.nextRound();
			return;
		}

		this.inputDispabled = false;
	}

	validateSequence(): boolean {
		if (this.inputSequence.length > this.round) {
			console.warn("validateSequence() -> input sequence too long");
			return false;
		}

		for (var i = 0; i < this.inputSequence.length; i++) {
			if (this.inputSequence[i] != this.passwordSequence[i]) {
				console.warn(
					`validateSequence() -> sequence mismatch on index ${i}`,
					this.inputSequence[i],
					this.currentRoundSequence[i]
				);
				return false;
			}
		}

		return true;
	}

  completed: boolean = false;

	nextRound() {
		if (this.round == this.passwordSequence.length) {
			console.warn("COMPLETED");
			this.inputDispabled = true;
      this.completed = true;
			return;
		}
		setTimeout(() => {
			this.round++;
			this.inputSequence = [];
			this.showCurrentRoundSequence();
		}, 1000);
	}

	reset() {
		this.lastButtonPressed = null;
		this.inputSequence = [];
		this.round = 1;
		this.color = "black";
		setTimeout(() => {
			this.showCurrentRoundSequence();
		}, 1000);
	}
}
