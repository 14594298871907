
import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "PipesPuzzle",
})
export default class PipesPuzzle extends Vue {
	pipes: any[] = [
		{
			id: 1,
			img: require("@/assets/pipes/1.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 2,
			img: require("@/assets/pipes/2.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 3,
			img: require("@/assets/pipes/3.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 4,
			img: require("@/assets/pipes/4.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 5,
			img: require("@/assets/pipes/5.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 6,
			img: require("@/assets/pipes/6.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 7,
			img: require("@/assets/pipes/7.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 8,
			img: require("@/assets/pipes/8.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 9,
			img: require("@/assets/pipes/9.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 10,
			img: require("@/assets/pipes/10.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 11,
			img: require("@/assets/pipes/11.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 12,
			img: require("@/assets/pipes/12.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 13,
			img: require("@/assets/pipes/13.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 14,
			img: require("@/assets/pipes/14.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 15,
			img: require("@/assets/pipes/15.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
		{
			id: 16,
			img: require("@/assets/pipes/16.png"),
			rotation: 90 + Math.floor(Math.random() * 3) * 90,
		},
	];

	selectedElement: any;

	elements: any[] = [
		{ name: "Osmium", symbol: "Os", atomicNumber: 76, atomicMass: 190.23 },
		{ name: "Iridium", symbol: "Ir", atomicNumber: 77, atomicMass: 192.22 },
		{ name: "Iodine", symbol: "I", atomicNumber: 53, atomicMass: 126.9 },
		{ name: "Sulfur", symbol: "S", atomicNumber: 16, atomicMass: 32.065 },
	];

	isElementModalOpen: boolean = false;
	displayElement(element: any) {
		this.selectedElement = element;
		this.isElementModalOpen = true;
	}

	rotate(pipe: any) {
		pipe.rotation += 90;
		if (pipe.rotation % 360 == 0) {
			pipe.rotation = 0;
		}
		this.validate();
	}

	mounted() {
		this.validate();
	}

	isValid: boolean = false;
	progress: number = 0;
	validate() {
		let validCount = this.pipes.filter((x) => x.rotation == 0).length;
		let totalCount = this.pipes.length;
		this.progress = (100 / totalCount) * validCount;
		this.isValid = validCount == totalCount;
	}
}
