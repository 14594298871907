
import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "MazePuzzle",
})
export default class MazePuzzle extends Vue {
	pieces: any[] = [
		{
			active: false,
			onImage: require("@/assets/maze/top_left_on.png"),
			offImage: require("@/assets/maze/top_left_off.png"),
		},
		{
			active: false,
			onImage: require("@/assets/maze/top_right_on.png"),
			offImage: require("@/assets/maze/top_right_off.png"),
		},
		{
			active: false,
			onImage: require("@/assets/maze/bottom_left_on.png"),
			offImage: require("@/assets/maze/bottom_left_off.png"),
		},
		{
			active: false,
			onImage: require("@/assets/maze/bottom_right_on.png"),
			offImage: require("@/assets/maze/bottom_right_off.png"),
		},
	];

	activatePiece(piece: any) {
		piece.active = true;
		setTimeout(() => {
			piece.active = false;
		}, 5000);
	}
}
