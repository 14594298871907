
  import { Component, Vue } from 'vue-property-decorator';

  @Component({
    name: "ModulatorPuzzle"
  })
  export default class ModulatorPuzzle extends Vue {
    
    alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ];

    password: string = 'RESHEP';

    get passwordArray(){
      let letters = this.password.split('');
      let result = [];
      for(var letter of letters){
        result.push(this.alphabet.indexOf(letter));
      }
      return result;
    }

    resultArray: any[] = [
      {value: 0, key: 0},
      {value: 0, key: 1},
      {value: 0, key: 2},
      {value: 0, key: 3},
      {value: 0, key: 4},
      {value: 0, key: 5},
    ]

    hintLabels: string [] = ['','','','','','']

    get resultStringArray(){
      return this.resultArray.map(x => {return this.alphabet[x.value]});
    }

    colors: string[] = [
      'primary',
      'primary',
      'primary',
      'primary',
      'primary',
      'primary',
    ]

    validating: boolean = false;

    validateResult(){
      this.validating = true;

      setTimeout(() => {
        for(var i = 0; i < this.resultArray.length; i++){
          let resultValue = this.resultArray[i].value;
          let passValue = this.passwordArray[i];
          let delta = Math.abs(resultValue - passValue);
          let color = delta == 0 ? 'green' : delta <= 3 ? 'yellow' : delta <= 6 ? 'orange' : 'red';
          this.colors[i] = color;
          this.hintLabels[i] = resultValue == passValue ? '✔' : resultValue > passValue ? '⬇' : '⬆';
          console.log(this.hintLabels);
          this.$forceUpdate();
        }
        this.validating = false;
      },3000);

    }

  }
