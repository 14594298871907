
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
	name: "HomeView",
})
export default class HomeView extends Vue {
	frequency: number = 88.0;
	targetFrequency: number = 97.5;

	isFm: boolean = false;

	@Watch("isFm")
	onFmChanged() {
		this.playCurrentAudio();
	}

	get isFmLabel() {
		return this.isFm ? "FM" : "AM";
	}

	power: boolean = false;

	get powerColor() {
		return this.power ? "green" : "red";
	}

	togglePower() {
		this.power = !this.power;
		if (this.power) {
			console.log(
				"🔵 -> file: RadioPuzzle.vue:68 -> HomeView -> togglePower -> this.power:",
				this.power
			);
			this.playCurrentAudio();
		} else {
			console.log(
				"🔵 -> file: RadioPuzzle.vue:68 -> HomeView -> togglePower -> this.power:",
				this.power
			);
			this.audioStop();
		}
	}

	get frequencyLabel() {
		let fr = parseFloat(this.frequency.toString()).toFixed(1);
		return `${fr} ${this.isFm ? "FM" : "AM"}`;
	}

	staticNoise = new Audio(require("@/assets/radio/radio_static.mp3"));
	passwordAudio = new Audio(require("@/assets/radio/anubis_tts.mp3"));

	playCurrentAudio() {
		this.audioStop();
		this.audioPlay();
	}

	audioPlay() {
		if (!this.power) {
			return;
		}
		let currentAudio = !this.isFm
			? this.staticNoise
			: this.frequency == this.targetFrequency
			? this.passwordAudio
			: this.staticNoise;

		currentAudio.currentTime = 0;
		currentAudio.play();
	}

	audioStop() {
		this.staticNoise.pause();
		this.passwordAudio.pause();
	}
}
